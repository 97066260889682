import React, { useEffect, useRef, useState } from "react";
import { jsbcCreateOrder } from "../../../api";
import lodash from 'lodash';

const JiangSuBank = () => {
  const payBtn = {
    0: 'https://staticfile.badazhou.com/20240912/8623eefc3a7eb6e067bfbe88fc015824.jpeg',
    1: 'https://staticfile.badazhou.com/20240912/272275c09b0934dd5875320cfbbc6bfd.jpeg',
    2: 'https://staticfile.badazhou.com/20240912/6b227320379a5292c11d98d7d796aed5.jpeg',
  }

  const [formData, setFormData] = useState({});
  const [payStatus, setPayStatus] = useState(0)
  const formRef = useRef(null);

  const { debounce } = lodash

  useEffect(() => {
    // jsbcPayNotify().then(res => {
    //   const { orderStatus } = res.data
    //   setPayStatus(orderStatus)
    // })
    setPayStatus(0)
  }, [])

  const goPay = async () => {
    if (payStatus !== 0) {
      return
    }
    try {
      const res = await jsbcCreateOrder({
        success_page_url: 'https://m.bdazhou.com/jsbc',
        wait_page_url: 'https://m.bdazhou.com/subject/jiangsuBank'
      });
      const { form_action, form_data } = res.data;

      console.log(form_action, form_data);

      // 更新表单数据
      setFormData({
        action: form_action,
        amount: form_data.amount,
        bizDate: form_data.bizDate,
        body: form_data.body,
        channelNo: form_data.channelNo,
        charset: form_data.charset,
        createDate: form_data.createDate,
        createTime: form_data.createTime,
        currency: form_data.currency,
        field1: form_data.field1,
        field2: form_data.field2,
        field3: form_data.field3,
        field4: form_data.field4,
        field5: form_data.field5,
        field6: form_data.field6,
        jumpSec: form_data.jumpSec,
        msgID: form_data.msgID,
        notifyUrl: form_data.notifyUrl,
        outerOrderNo: form_data.outerOrderNo,
        partnerBuyerId: form_data.partnerBuyerId,
        partnerId: form_data.partnerId,
        publicKeyCode: form_data.publicKeyCode,
        returnUrl: form_data.returnUrl,
        service: form_data.service,
        sign: form_data.sign,
        signType: form_data.signType,
        jumpConfig: form_data.jumpConfig,
        subject: form_data.subject,
        svrCode: form_data.svrCode,
        version: form_data.version
      });

      formRef.current.submit();
    } catch (error) {
      console.error("支付失败:", error);
    }
  };

  return (
    <div className="js-img-wrapper">
      <img src="https://staticfile.badazhou.com/20240911/a19a5502f77948720da78818cd81f033.jpeg" alt="" />
      <img src="https://staticfile.badazhou.com/20240911/14233a0d23319c98159a9d5312c9b577.jpeg" alt="" />
      <img className="js-btn" onClick={() => debounce(goPay, 800)()} src={payBtn[payStatus]} alt="" />

      <form ref={formRef} action={formData.action} method="post">
        <input type="hidden" name="amount" value={formData.amount || ""} />
        <input type="hidden" name="bizDate" value={formData.bizDate || ""} />
        <input type="hidden" name="body" value={formData.body || ""} />
        <input type="hidden" name="channelNo" value={formData.channelNo || ""} />
        <input type="hidden" name="charset" value={formData.charset || ""} />
        <input type="hidden" name="createDate" value={formData.createDate || ""} />
        <input type="hidden" name="createTime" value={formData.createTime || ""} />
        <input type="hidden" name="currency" value={formData.currency || ""} />
        <input type="hidden" name="field1" value={formData.field1 || ""} />
        <input type="hidden" name="field2" value={formData.field2 || ""} />
        <input type="hidden" name="field3" value={formData.field3 || ""} />
        {/* <input type="hidden" name="field4" value={formData.field4 || ""} />
        <input type="hidden" name="field5" value={formData.field5 || ""} />
        <input type="hidden" name="field6" value={formData.field6 || ""} /> */}
        <input type="hidden" name="jumpSec" value={formData.jumpSec || ""} />
        <input type="hidden" name="msgID" value={formData.msgID || ""} />
        <input type="hidden" name="notifyUrl" value={formData.notifyUrl || ""} />
        <input type="hidden" name="outerOrderNo" value={formData.outerOrderNo || ""} />
        <input type="hidden" name="partnerBuyerId" value={formData.partnerBuyerId || ""} />
        <input type="hidden" name="partnerId" value={formData.partnerId || ""} />
        <input type="hidden" name="publicKeyCode" value={formData.publicKeyCode || ""} />
        <input type="hidden" name="returnUrl" value={formData.returnUrl || ""} />
        <input type="hidden" name="service" value={formData.service || ""} />
        <input type="hidden" name="sign" value={formData.sign || ""} />
        <input type="hidden" name="signType" value={formData.signType || ""} />
        {/* <input type="hidden" name="jumpConfig" value={formData.jumpConfig || ""} /> */}
        <input type="hidden" name="subject" value={formData.subject || ""} />
        <input type="hidden" name="svrCode" value={formData.svrCode || ""} />
        <input type="hidden" name="version" value={formData.version || ""} />
      </form>
    </div>
  );
};

export default JiangSuBank;
