import React, { useState, useEffect, } from "react";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";
import { wechatGenerateScheme, officialWechatJsConfig } from "../../api";
import "./style.scss";

export default (props) => {
  const [params, setParams] = useState(null);
  const { search } = useLocation();


  // 初始化设置参数
  useEffect(() => {
    const parseUrl = parse(search);
    const path = parseUrl.path ? decodeURIComponent(parseUrl.path) : "/pages/index/index";
    let query = parseUrl.query ? decodeURIComponent(parseUrl.query) : "";

    console.log('query', query);
    if (query.indexOf('-') >= 0) {
      let queryString = '';
      let queryArr = query.split('-');
      for (let i = 0; i < queryArr.length; i = i + 2) {
        if (i + 2 < queryArr.length) {
          queryString = queryString + `${queryArr[i]}=${queryArr[i + 1]}&`
        } else {
          queryString = queryString + `${queryArr[i]}=${queryArr[i + 1]}`
        }
      }
      console.log(' queryString', queryString);
      query = queryString;
    }

    const url = query ? `${path}?${query}` : path;
    const params = { path, query, url };

    // 插入微信js
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js';
    document.head.appendChild(script);
    // 请求配置数据
    const href = window.location.href;
    const jsApis = 'updateAppMessageShareData'
    officialWechatJsConfig({ url: href, jsApis }).then((res) => {
      const info = res.data;
      window.wx.config({
        debug: false,
        appId: info.appId,
        timestamp: info.timestamp,
        nonceStr: info.nonceStr,
        signature: info.signature,
        jsApiList: info.jsApiList,
        openTagList: [
          'wx-open-launch-weapp',
          'wx-open-launch-app'
        ]
      });

      setTimeout(() => {
        const randomNum = parseInt(Math.random() * 100000000);
        const newQuery = `${query}&randomNum=${randomNum}`;
        wechatGenerateScheme({ path, query: newQuery }).then((res) => {
          window.location.href = res.data.scheme;
          // console.log(res.data.scheme);
        }).catch((err) => { });
      }, 100);

    }).catch((err) => { });

    setParams({ ...params });


  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // function goToMiniApp() {
  //   window.location.href = schemeRef.current.scheme;
  // }

  return (
    <div className='goto-wxapp-wrapper weixin'>
      {params && (
        <>

          <img style={{ display: 'block', width: '250px', height: '99px', margin: '0 auto' }} src="https://staticfile.badazhou.com/20230516/21434acdd1e8f925a084077496608663.gif" alt="" />

          <div style={{ width: '6.9rem', height: '.8rem' }}>
            {/* <div>{params.url}</div> */}
            <wx-open-launch-weapp
              id="launch-btn"
              username="gh_8b405445c453"
              path={params.url}
              style={{ width: '6.9rem', height: '.8rem' }}
            >
              <script type="text/wxtag-template">
                <img style={{ width: '100%', height: '100%', opacity: '0' }} src="https://staticfile.badazhou.com/20210416/6050db2e9cde806128355a69450e087d.jpeg" alt="" />
              </script>
            </wx-open-launch-weapp>

          </div>


        </>
      )}
    </div>
  );
}
