import React, { useEffect, useRef, useState } from 'react';
import { wechatGenerateScheme } from "../../api";
import './style.scss';

const merchantNo = 'vUNad3fTJNkp'

const FlyertRedirect = () => {
  const [isShowLogin, setIsShowLogin] = useState(false)
  const schemeRef = useRef();

  const path = "/pages/subject/springActivity/springActivity";
  const randomNum = parseInt(Math.random() * 100000000);
  const query = `merchantNo=${merchantNo}&randomNum=${randomNum}`;

  useEffect(() => {
    // 打开微信小程序
    wechatGenerateScheme({ path, query }).then((res) => {
      schemeRef.current = res.data;
    }).catch((err) => { });
    // eslint-disable-next-line
  }, [])

  const showLoginBox = () => {
    setIsShowLogin(true)
  }

  const goToWxApp = () => {
    console.log(schemeRef.current);
    window.location.href = schemeRef.current.scheme;
  }

  return <>
    <div className={'xhs-container ' + isShowLogin ? 'popup' : ''}>
      <img className="img1" src="https://staticfile.badazhou.com/20241210/289444938700783b0e9669b4e97f7eb1.jpeg" alt='' />
      <div className="lingqu-box" >
        {
          !isShowLogin ? <div className='get-box get-box-status-0' onClick={showLoginBox}>立即报名</div> :
            <div className='get-box get-box-status-1' onClick={goToWxApp}>报名成功，前往八大洲小程序预订</div>
        }
      </div>
      <img className="img4" src="https://staticfile.badazhou.com/20241210/476e168c2e5307a7c8d641f2c32d3b33.jpeg" alt='' />
      <img className="img5" src="https://staticfile.badazhou.com/20241210/9b6e12b20423446a8663cac1797a0d71.jpeg" alt='' />
      <img className="img6" src="https://staticfile.badazhou.com/20241210/19b496dddd59e9d257126da13cda03d1.jpeg" alt='' />

      <img className="img7" src="https://staticfile.badazhou.com/20241210/d8ca732e0d90084e4f2b9d70c6df7c24.jpeg" alt='' />
      <img className="img8" src="https://staticfile.badazhou.com/20241210/d621f5c042f31e04c556771755169fca.jpeg" alt='' />
      <img className="img9" src="https://staticfile.badazhou.com/20241210/401b578209f16f9a742655b1b346dcde.jpeg" alt='' />

      <img className="img10" src="https://staticfile.badazhou.com/20241210/084377740046d3cfbcc076511af01ba3.jpeg" alt='' />
      <img className="img11" src="https://staticfile.badazhou.com/20241210/2ede3f37cd094d1bf7ce67c0965e8cb9.jpeg" alt='' />
    </div>
  </>
}

export default FlyertRedirect