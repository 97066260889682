import queryString from 'query-string';
import { get, post } from './request';

/* 雀巢活动 开始 */
// 雀巢登陆
export function quechaoLogin(query) {
  let url = '/www/quechao/login';
  url = queryString.stringifyUrl({ url, query });
  return get(url);
}


// 是否参与雀巢活动
export function quechaoHasJoin(query) {
  let url = '/www/quechao/hasJoin';
  url = queryString.stringifyUrl({ url, query });
  return get(url);
}

// 参与活动
export function quechaoJoin(data) {
  return post('/www/quechao/join', data);
}

// 提交入住人信息
export function quechaoCheck(data) {
  return post('/www/quechao/checkinInfo', data);
}
/* 雀巢活动 结束 */

// 江苏银行
// 生成订单
export function jsbcCreateOrder(data) {
  return post('/gapi/app/pay_order/create', data);
}
// 支付通知
export function jsbcPayNotify(data) {
  return post('/gapi/app/pay_order/notify', data);
}